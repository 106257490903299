// import external libraries

import { createApp } from 'vue'

// import css stylesheets
import '@/assets/css/index.css'
import '@/assets/css/style.css'
import '@/assets/css/vue-popper.css';
import '@/assets/images/fontawesome.js';
import Lara from '@/presets/lara';

// noinspection ES6UnusedImports
import _ from 'lodash-es';
import Buefy from '@ntohq/buefy-next';
import * as Sentry from '@sentry/vue';
import { Integrations as TracingIntegration } from '@sentry/tracing';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import { Vue3Mq } from 'vue3-mq';
import AutoRotate from 'vue-jpeg-auto-rotation';

// import PrimeVue components
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Galleria from 'primevue/galleria';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TreeSelect from 'primevue/treeselect';
import AutoComplete from 'primevue/autocomplete';
import SplitButton from 'primevue/splitbutton';
import StyleClass from 'primevue/styleclass';
import Ripple from 'primevue/ripple';
import Chips from 'primevue/chips';
import PAvatar from 'primevue/avatar';
import InputNumber from 'primevue/inputnumber';
import Sidebar from 'primevue/sidebar';
import InputSwitch from 'primevue/inputswitch';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import RadioButton from 'primevue/radiobutton';
import CascadeSelect from 'primevue/cascadeselect';
import MultiSelect from 'primevue/multiselect';

import VueConfetti from 'vue-confetti';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

// import internal Libraries
// noinspection ES6UnusedImports

import api from './api'; // eslint-disable-line no-unused-vars
import App from './App.vue';
import eventBus from '@/plugins/event_bus';
import breadcrumbs from '@/plugins/breadcrumbs/breadcrumbs';
import KeepScrollPosition from '@/plugins/navigation/keep_scroll_position';
import Avatar from '@/plugins/avatar/Avatar.vue';
import FormatCurrency from '@/app/shared_components/currency/FormatCurrency.vue';
import buttons from '@/app/shared_components/buttons/buttons.js';
import i18n from '@/i18n/index';
import router from '@/router/index';
import { Auth0Plugin } from '@/auth';
import store from '@/store/index';
import toasts from '@/app/util/toasts';
import sequentialPromise from '@/app/util/synchronous_promise';
import VPopper from '@/app/shared_components/tooltips/VPopper.vue';
import TitleSmall from '@/app/shared_components/titles/TitleSmall.vue';
import Modal from '@/app/shared_components/modals/TheModal.vue';
import truncate from '@/plugins/truncate';

// import constants
import { ZIGGU_API_URL } from '@/app/util/urls';
import flipper from '@/plugins/flipper';
import { FT } from '@/app/data/flipper_constants';

const app = createApp(App)

app.use({
  install() {
    app.config.globalProperties.emitter = eventBus;
  },
});

app.use(store);
app.use(router);
app.use(i18n);

app.use(Buefy, { defaultIconPack: 'fas' });

// Initialize Sentry
if (!import.meta.env.IS_LOCAL) {
  Sentry.init({
    app,
    dsn: 'https://be16e9a319304d88b103e2300a4f669f@sentry.io/1190623',
    release: `${import.meta.env.RELEASE_VERSION}`,
    environment: `${import.meta.env.VITE_ENV}`,
    ignoreErrors: [
      'TypeError: NetworkError when attempting to fetch resource.',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new DedupeIntegration(),
      new TracingIntegration.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [`${ZIGGU_API_URL.replace('https://', '')}`],
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.25, // percentage
    normalizeDepth: 8,
    // prevent 401 error logs (expired sessions, see api/index.js) being sent to Sentry
    beforeSend(event, hint) {
      const isUnauthorizedError = hint
        && hint.originalException
        && hint.originalException.response
        && hint.originalException.response.status
        && hint.originalException.response.status === 401;
      const isSentryDiscard = hint
        && hint.originalException
        && hint.originalException.sentry_discard
        && hint.originalException.sentry_discard === true;
      const isNonErrorException = hint
        && hint.originalException
        && hint.originalException.error
        && event
        && event.message
        && event.message.startsWith('Non-Error exception captured');
      const isChunkError = (event.message && event.message.startsWith('Loading chunk '))
        || (event.name && event.name === 'ChunkLoadError');
      // decided to ignore these, due to noise, mostly due to Safari https://github.com/axios/axios/issues/4420
      const isNetworkError = event.name && event.name === 'AxiosError'
        && event.message && event.message === 'Network Error';

      if (isUnauthorizedError || isSentryDiscard || isChunkError || isNetworkError) {
        return null;
      }

      // Errors from Auth0 extends original Error class and cause issues with Sentry
      if (isNonErrorException) {
        Sentry.withScope((scope) => {
          scope.setExtra('nonErrorException', true);
          Sentry.captureException(hint.originalException.error);
        });
        return null;
      }

      return event;
    },
  });
} else {
  app.config.performance = true;
}

app.use(Auth0Plugin, {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  client_id: import.meta.env.VITE_AUTH0_APP_CLIENT_ID,
  audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
  onRedirectCallback: (appState) => {
    const path = _.get(appState, 'targetUrl', window.location.pathname);
    const query = _.get(appState, 'params', {});

    router.push({ path, query });
  },
});

app.use({
  install() {
    window._ = _;
    app.config.globalProperties.window = window;
    app.config.globalProperties.$_ = _;
    app.config.globalProperties.$FT = FT;
  }
});
app.config.globalProperties.sequentialPromise = sequentialPromise;

app.use(VueConfetti);
app.mixin(toasts);

app.component('Avatar', Avatar);
app.component('FormatCurrency', FormatCurrency);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('Modal', Modal);
app.component('VPopper', VPopper);
app.component('TitleSmall', TitleSmall);
app.component('Dropdown', Dropdown);
app.component('Galleria', Galleria);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('PrimeDialog', Dialog);
app.component('TreeSelect', TreeSelect);
app.component('Toast', Toast);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('RadioButton', RadioButton);
app.component('Sidebar', Sidebar);
app.component('Chips', Chips);
app.component('PButton', Button);
app.component('PMenu', Menu);
app.component('AutoComplete', AutoComplete);
app.component('SplitButton', SplitButton);
app.component('PAvatar', PAvatar);
app.component('CascadeSelect', CascadeSelect);
app.component('MultiSelect', MultiSelect);

app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('tooltip', Tooltip);

app.use(PrimeVue, {
  unstyled: true,
  pt: Lara,
  ripple: true
});

app.use(AutoRotate);
app.use(breadcrumbs);
app.use(flipper, store);
app.use(KeepScrollPosition);
app.use(buttons);
app.use(Vue3Mq);
app.use(ToastService);

const truncatePlugin = {
  install () {
    app.truncate = truncate
    app.config.globalProperties.$truncate = truncate
  }
}
app.use(truncatePlugin)

api.interceptors.response.use(
  (response) => {
    // verify cache integrity by comparing "current" object between API (via custom HTTP header) and APP (via vuex)
    const timestamps_api = _.chain(response.headers['current-integrity'])
      .split(',')
      .map((timestamp) => [timestamp.split('=')[0], parseInt(timestamp.split('=')[1], 10)])
      .fromPairs()
      .value();
    const timestamps_app = _.chain(store.getters.current_integrity)
      .split(',')
      .map((timestamp) => [timestamp.split('=')[0], parseInt(timestamp.split('=')[1], 10)])
      .fromPairs()
      .value();
    if (timestamps_app['user.logout_at'] < timestamps_api['user.logout_at']) {
      app.config.globalProperties.emitter.$emit('invalidateSession');
    }
    if (timestamps_app['client.updated_at'] < timestamps_api['client.updated_at']) {
      app.config.globalProperties.emitter.$emit('fetchClient');
      app.config.globalProperties.emitter.$emit('fetchFeatureToggles');
    }
    if (timestamps_app['profile.notified_at'] < timestamps_api['profile.notified_at']) {
      app.config.globalProperties.emitter.$emit('fetchProfile');
      app.config.globalProperties.emitter.$emit('fetchNotificationSubgroups');
    } else if (timestamps_app['profile.updated_at'] < timestamps_api['profile.updated_at']) {
      app.config.globalProperties.emitter.$emit('fetchProfile');
      app.config.globalProperties.emitter.$emit('fetchFeatureToggles');
    }
    return response;
  },
  (error) => {
    // let app handle unauthorized errors
    if (error.response !== undefined && error.response.status === 401) {
      app.config.globalProperties.emitter.$emit('errors:401');
      throw error;
    // } else if (error.response !== undefined && error.response.status === 500) {
    //   Sentry.captureException(error);
    //   this.emitter.$emit('errors:500');
    //   throw error;
    // let app handle forbidden errors but do propagate error to Sentry (as this shouldn't happen)
    } else if (error.response !== undefined && error.response.status === 403) {
      Sentry.captureException(error);
      app.config.globalProperties.emitter.$emit('errors:403');
      throw error;
    // error which likely indicates access was revoked to a resource
    } else if (error.response !== undefined && error.response.status === 404) {
      app.config.globalProperties.emitter.$emit('errors:404');
    // error without a response can e.g. be an unauthorized preflight OPTION request
    } else {
      // propagate other errors (to Sentry)
      throw error;
    }
  },
);

app.mount('#app');
