const ASSIGNMENT_TYPES = Object.freeze({
  TYPE_FOLLOW: 'follow',
  TYPE_MAINTAIN: 'maintain',
  TYPE_RESPONSIBLE: 'responsible',
  TYPE_ACCESS: 'access',
  TYPE_SALES: 'sales',
});

export const ATTACHABLE_TYPES = Object.freeze({
  ANNOUNCEMENT: 'Announcement',
  BUILDING: 'Building',
  CHOICE: 'Choice',
  CHOICE_TEMPLATE: 'ChoiceTemplate',
  DECISION: 'Decision',
  DECISION_TYPE: 'DecisionType',
  INSPIRATION: 'Inspiration',
  INSTALLMENT: 'Installment',
  ISSUE: 'Issue',
  LOT: 'Lot',
  PHASE: 'Phase',
  PROJECT: 'Project',
  PROPOSAL: 'Proposal',
  PROPOSAL_TEMPLATE: 'ProposalTemplate',
  QUESTION: 'Question',
  TICKET: 'Ticket',
  TODO: 'Todo',
  TODO_LIST: 'TodoList',
  UNIT: 'Unit',
  EMAIL_CONVERSATION: 'EmailConversation',
});

export const ATTACHABLE_GROUP_TYPES = Object.freeze({
  CHOICE_LIST: 'ChoiceList',
  CHOICE_LIST_TEMPLATE: 'ChoiceListTemplate',
})

const CATEGORY_OUTDOORS = Object.freeze({
  CATEGORY_OUTDOOR_GARDEN: 'garden',
  CATEGORY_OUTDOOR_TERRACE: 'terrace',
  CATEGORY_OUTDOOR_TERRACE_GARDEN: 'terrace_garden',
});

const CLIENT_STATUSES = Object.freeze({
  STATUS_CUSTOM: 'custom',
  STATUS_DEMO: 'demo',
  STATUS_DISABLED: 'disabled',
  STATUS_PAYING: 'paying',
  STATUS_TEST: 'test',
  STATUS_TRIAL: 'trial',
  STATUS_CHURNED: 'churned',
});

const CLIENT_PACKAGE_TYPES = Object.freeze({
  PACKAGE_TYPE_STARTER: 'starter',
  PACKAGE_TYPE_EXPERT: 'expert',
  PACKAGE_TYPE_ENTERPRISE: 'enterprise',
});

const CLIENT_PRICING_TYPES = Object.freeze({
  PRICING_TYPE_STARTER: 'fixed',
  PRICING_TYPE_EXPERT: 'twilight',
  PRICING_TYPE_ENTERPRISE: 'rundle',
});

const CLIENT_BUNDLE_TYPES = Object.freeze({
  BUNDLE_TYPE_BASE: 'base',
  BUNDLE_TYPE_UPSELL: 'upsell',
  BUNDLE_TYPE_UPGRADE: 'upgrade',
  BUNDLE_TYPE_DOWNSELL: 'downsell',
  BUNDLE_TYPE_DOWNGRADE: 'downgrade',
  BUNDLE_TYPE_CHURN: 'churn',
});

const CUSTOMER_CATEGORIES = Object.freeze({
  CATEGORY_ENDUSER: 'enduser',
  CATEGORY_INVESTOR: 'investor',
});

export const DECISION_STATUSES = Object.freeze({
  APPROVED_AFTER_DUE_DATE: 'approved_after_due_date',
  APPROVED_BEFORE_DUE_DATE: 'approved_before_due_date',
  CREATED: 'created',
  EXPIRED: 'expired',
  EXPIRED_WITH_PROPOSAL: 'expired_with_proposal',
  HAS_PROPOSAL: 'has_proposal',
  NOT_CREATED: 'not_created',
  REQUESTED_CHANGES: 'requested_changes',
  NOT_INTERESTED: 'not_interested',
});

const ATTACHMENT_CATEGORIES = Object.freeze({
  CATEGORY_ADMIN: 'admin',
  CATEGORY_AFTERCARE: 'aftercare',
  CATEGORY_AS_BUILT: 'as_built',
  CATEGORY_BROCHURE: 'brochure',
  CATEGORY_IMAGE: 'image',
  CATEGORY_INVOICE: 'invoice',
  CATEGORY_LEGAL: 'legal',
  CATEGORY_MANUAL: 'manual',
  CATEGORY_OTHER: 'other',
  CATEGORY_PLAN: 'plan',
  CATEGORY_QUOTE: 'quote',
});

export const DEAL_STATUSES = Object.freeze({
  AVAILABLE: 'available',
  INTERESTED: 'interested',
  RESERVED: 'reserved',
  SOLD: 'sold',
  LOST: 'lost',
});

export const DEAL_STATUSES_STYLES = Object.freeze({
  [DEAL_STATUSES.AVAILABLE]: 'darkgrey',
  [DEAL_STATUSES.RESERVED]: '#296fa8',
  [DEAL_STATUSES.SOLD]: '#257953',
  [DEAL_STATUSES.LOST]: '#cc0f35',
});

export const EMAIL_ADDRESS_STATUSES = Object.freeze({
  STATUS_UNKNOWN: 'unknown',
  STATUS_DELIVERABLE: 'deliverable',
  STATUS_HARD_BOUNCE: 'hard_bounce',
  STATUS_SOFT_BOUNCE: 'soft_bounce',
  STATUS_SPAM_COMPLAINT: 'spam_complaint',
  STATUS_SPAM_NOTIFICATION: 'spam_notification',
  STATUS_ISP_BLOCK: 'isp_block',
  STATUS_MESSAGE_DELAYED: 'message_delayed',
  STATUS_OTHER: 'other',
});

const EMAIL_NOTIFICATIONS_INTERVALS = Object.freeze({
  EMAIL_NOTIFICATION_INTERVAL_DAILY: 'daily',
  EMAIL_NOTIFICATION_INTERVAL_HOURLY: 'hourly',
  EMAIL_NOTIFICATION_INTERVAL_TWICE_DAILY: 'twice_daily',
  EMAIL_NOTIFICATION_INTERVAL_WEEKLY: 'weekly',
});

const EMPLOYEE_ROLES = Object.freeze({
  ROLE_MAINTAINER: 'maintainer',
  ROLE_OWNER: 'owner',
});

const EXPORT_STATES = Object.freeze({
  STATE_COMPLETED: 'completed',
  STATE_DOWNLOADED: 'downloaded',
  STATE_QUEUED: 'queued',
  STATE_STARTED: 'started',
});

const EXPORT_TYPES = Object.freeze({
  EXPORT_TYPE_CONTACTS: 'contacts',
  EXPORT_TYPE_CONVERSATIONS: 'conversations',
  EXPORT_TYPE_ISSUE_LIST_TYPE: 'issue_list_type',
  EXPORT_TYPE_UNIT_INFORMATION: 'unit_information',
  EXPORT_TYPE_UNITS_CUSTOMER_PROJECT: 'units_customer_project',
  EXPORT_TYPE_DECISION_TYPE: 'decision_type',
  EXPORT_TYPE_PROPOSAL: 'proposal',
});

const INSTALLMENT_PRICE_FRACTION_TYPES = {
  FRACTION_TYPE_RELATIVE: 'relative',
  FRACTION_TYPE_ABSOLUTE: 'absolute',
};

const ISSUE_STATUSES = Object.freeze({
  STATUS_CREATED: 'created',
  STATUS_ONGOING: 'ongoing',
  STATUS_COMPLETED: 'completed',
  STATUS_NOT_COMPLETED: 'not_completed',
});

const ISSUE_APROVAL_STATUSES = Object.freeze({
  STATUS_APPROVAL_REQUESTED: 'approval_requested',
  STATUS_DECLINED: 'declined',
  STATUS_APPROVED: 'approved',
  STATUS_APPROVAL_NOT_REQUESTED: 'approval_not_requested',
});

export const GRANT_RECORDING_DURATION = 3600; // seconds

export const LANGUAGES = Object.freeze([
  'en',
  'fr',
  'nl',
]);

export const LANGUAGES_CUSTOMER_ONLY = Object.freeze([
  'pt',
]);

export const LEVEL_INDICES = Object.freeze({
  PROJECT: 0,
  PHASE: 1,
  LOT: 2,
  BUILDING: 3,
  UNIT: 4,
});

export const LEVEL_TYPE = Object.freeze({
  PROJECT: 'Project',
  PHASE: 'Phase',
  LOT: 'Lot',
  BUILDING: 'Building',
  UNIT: 'Unit',
});

export const LEVEL_TYPES = new Set(Object.values(LEVEL_TYPE));

export const PROPOSAL_TYPES = Object.freeze({
  FIXED: 'fixed',
  CHOICES: 'choices',
});

const MEASUREMENT_SYSTEMS = Object.freeze({
  MEASUREMENT_SYSTEM_IMPERIAL: 'imperial',
  MEASUREMENT_SYSTEM_METRIC: 'metric',
});

const ORIENTATIONS = Object.freeze({
  ORIENTATION_EAST: 'east',
  ORIENTATION_NORTH: 'north',
  ORIENTATION_NORTHEAST: 'northeast',
  ORIENTATION_NORTHWEST: 'northwest',
  ORIENTATION_SOUTH: 'south',
  ORIENTATION_SOUTHEAST: 'southeast',
  ORIENTATION_SOUTHWEST: 'southwest',
  ORIENTATION_WEST: 'west',
});

const PROFILE_STATUSES = Object.freeze({
  STATUS_ACTIVE: 'active',
  STATUS_ADDED: 'added',
  STATUS_BLOCKED: 'blocked',
  STATUS_DISCARDED: 'discarded',
  STATUS_INVITED: 'invited',
});

const PROFILE_STATUSES_STYLES = Object.freeze({
  [PROFILE_STATUSES.STATUS_ACTIVE]: '#effaf5',
  [PROFILE_STATUSES.STATUS_INVITED]: '#fffaeb',
  [PROFILE_STATUSES.STATUS_ADDED]: '#eff5fb',
  [PROFILE_STATUSES.STATUS_BLOCKED]: '#feecf0',
});

const PROFILE_STATUSES_STYLES_TEXT = Object.freeze({
  [PROFILE_STATUSES.STATUS_ACTIVE]: '#257953',
  [PROFILE_STATUSES.STATUS_INVITED]: '#946c00',
  [PROFILE_STATUSES.STATUS_ADDED]: '#296fa8',
  [PROFILE_STATUSES.STATUS_BLOCKED]: '#cc0f35',
});

const PROFILE_TITLES = Object.freeze({
  TITLE_DR: 'dr',
  TITLE_MR: 'mr',
  TITLE_MRS: 'mrs',
  TITLE_PROFESSOR: 'professor',
  TITLE_SIRMADAM: 'sirmadam',
});

export const PROFILE_TYPES = Object.freeze({
  CUSTOMER: 'Customer',
  LEAD: 'Lead',
  EMPLOYEE: 'Employee',
  PARTNER: 'Partner',
  SUPERVISOR: 'Supervisor',
  EMAIL_CONVERSATION: 'EmailConversation',
});

const PROJECT_STATUSES = Object.freeze({
  STATUS_ENABLED: 'enabled',
  STATUS_PENDING: 'pending',
});

export const PARTNER_TYPES = Object.freeze({
  PROJECT_PARTNER: 'ProjectPartner',
  DECISION_PARTNER: 'DecisionPartner',
});

export const PROJECT_TYPES = Object.freeze({
  MUP: 'mup',
  MUP_AND_SUP: 'mup_and_sup',
  SUP: 'sup',
})

export const INSTALLMENT_STATUSES_WITH_DEADLINE = Object.freeze({
  DRAFT: 'draft',
  DRAFT_AFTER_DUE_DATE: 'draft_after_due_date',
  PAID: 'paid',
  PAID_AFTER_DUE_DATE: 'paid_after_due_date',
  REQUESTED: 'requested',
  REQUESTED_AFTER_DUE_DATE: 'requested_after_due_date',
});

const INSTALLMENT_PRICE_TYPES = {
  PRICE_TYPE_TOTAL: 'price_value',
  PRICE_TYPE_SURCHARGE: 'surcharge',
  PRICE_TYPE_CUSTOM_AMOUNT: 'custom_amount',
  PRICE_TYPE_CONSTRUCTION: 'price_value_construction',
  PRICE_TYPE_CONSTRUCTION_EXISTING: 'price_value_construction_existing',
  PRICE_TYPE_FEES: 'price_value_fees',
  PRICE_TYPE_LAND_VALUE: 'price_value_land',
};

export const RECORD_TYPE = Object.freeze({
  ACTIVITY: 'Activity',
  ANNOUNCEMENT: 'Announcement',
  ANNOUNCEMENT_LEVEL: 'AnnouncementLevel',
  ASSIGNMENT: 'Assignment',
  ATTACHMENT: 'Attachment',
  ATTACHMENT_CATEGORY: 'AttachmentCategory',
  ATTACHMENT_TEMPLATE: 'AttachmentTemplate',
  AUTH0_LOG: 'Auth0Log',
  BILLING: 'Billing',
  BUILDING: 'Building',
  BUNDLE: 'Bundle',
  CHOICE: 'Choice',
  CHOICE_LIST: 'ChoiceList',
  CHOICE_LIST_TEMPLATE: 'ChoiceListTemplate',
  CHOICE_TEMPLATE: 'ChoiceTemplate',
  CLIENT: 'Client',
  COMPANY: 'Company',
  CUSTOMER: 'Customer',
  CUSTOMER_GROUP: 'CustomerGroup',
  DEAL: 'Deal',
  DECISION: 'Decision',
  DECISION_PROPOSAL: 'DecisionProposal',
  DECISION_PROPOSAL_TEMPLATE: 'DecisionProposalTemplate',
  DECISION_TYPE: 'DecisionType',
  DECISION_TYPE_CATEGORY: 'DecisionTypeCategory',
  DOCUMENT: 'Document',
  DOCUMENT_TEMPLATE: 'DocumentTemplate',
  EMAIL: 'Email',
  EMAIL_BOUNCE: 'EmailBounce',
  EMAIL_CLICK: 'EmailClick',
  EMAIL_CONVERSATION: 'EmailConversation',
  EMAIL_DELIVERY: 'EmailDelivery',
  EMAIL_EVENT: 'EmailEvent',
  EMAIL_OPEN: 'EmailOpen',
  EMAIL_SPAM: 'EmailSpam',
  EMPLOYEE: 'Employee',
  EMPLOYEE_PROJECT: 'EmployeeProject',
  EXPORT: 'Export',
  EVENT: 'Event',
  FEATURE_TOGGLE: 'FeatureToggle',
  FOLLOW: 'Follow',
  FRACTION: 'Fraction',
  FRACTION_CATEGORY: 'FractionCategory',
  GROUP_CUSTOMER: 'GroupCustomer',
  GROUP_LEAD: 'GroupLead',
  GROUP_PARTNER: 'GroupPartner',
  INSPIRATION: 'Inspiration',
  INSPIRATION_LIST: 'InspirationList',
  INSTALLMENT: 'Installment',
  INTEGRATION: 'Integration',
  INTEGRATION_ERROR: 'IntegrationError',
  INVOICE: 'Invoice',
  ISSUE: 'Issue',
  ISSUE_CATEGORY: 'IssueCategory',
  ISSUE_LIST: 'IssueList',
  ISSUE_LIST_TYPE: 'IssueListType',
  ISSUE_STATUS: 'IssueStatus',
  LEAD: 'Lead',
  LEAD_GROUP: 'LeadGroup',
  LOG_DATA: 'LogData',
  LOT: 'Lot',
  MARKETPLACE_PARTNER: 'MarketplacePartner',
  MENTION: 'Mention',
  MESSAGE: 'Message',
  MESSAGE_TRANSFER: 'MessageTransfer',
  MILESTONE: 'Milestone',
  NOTIFICATION: 'Notification',
  NOTIFICATION_SUBGROUP: 'NotificationSubgroup',
  OFFER: 'Offer',
  PARTICIPANT: 'Participant',
  PARTNER: 'Partner',
  PARTNER_GROUP: 'PartnerGroup',
  PHASE: 'Phase',
  PROJECT: 'Project',
  PROPOSAL: 'Proposal',
  PROPOSAL_TEMPLATE: 'ProposalTemplate',
  PROFESSIONAL_SERVICE: 'ProfessionalService',
  PUBLIC_TOKEN: 'PublicToken',
  QUESTION: 'Question',
  QUESTION_LIST: 'QuestionList',
  REMINDER: 'Reminder',
  SESSION: 'Session',
  SIGNATURE: 'Signature',
  SIGNATURE_TEMPLATE: 'SignatureTemplate',
  SPACE: 'Space',
  SUPERVISOR: 'Supervisor',
  SURVEY: 'Survey',
  SURVEY_ANSWER: 'SurveyAnswer',
  SURVEY_QUESTION: 'SurveyQuestion',
  SURVEY_TYPE: 'SurveyType',
  TENANT: 'Tenant',
  THEME: 'Theme',
  TICKET: 'Ticket',
  TICKET_CATEGORY: 'TicketCategory',
  TODO: 'Todo',
  TODO_LIST: 'TodoList',
  UNIT: 'Unit',
  USER: 'User',
});

// synced with same constant in app
export const RECORD_TYPES_AUDITED = [
  RECORD_TYPE.ANNOUNCEMENT,
  RECORD_TYPE.ATTACHMENT,
  RECORD_TYPE.ATTACHMENT_CATEGORY,
  RECORD_TYPE.ATTACHMENT_TEMPLATE,
  RECORD_TYPE.BILLING,
  RECORD_TYPE.BUILDING,
  RECORD_TYPE.BUNDLE,
  RECORD_TYPE.CHOICE,
  RECORD_TYPE.CHOICE_LIST,
  RECORD_TYPE.CHOICE_LIST_TEMPLATE,
  RECORD_TYPE.CHOICE_TEMPLATE,
  RECORD_TYPE.CLIENT,
  RECORD_TYPE.COMPANY,
  RECORD_TYPE.CUSTOMER,
  RECORD_TYPE.DEAL,
  RECORD_TYPE.DECISION,
  RECORD_TYPE.DECISION_TYPE,
  RECORD_TYPE.EMPLOYEE,
  RECORD_TYPE.GROUP_CUSTOMER,
  RECORD_TYPE.INTEGRATION,
  RECORD_TYPE.INVOICE,
  RECORD_TYPE.ISSUE,
  RECORD_TYPE.ISSUE_CATEGORY,
  RECORD_TYPE.ISSUE_LIST,
  RECORD_TYPE.ISSUE_LIST_TYPE,
  RECORD_TYPE.ISSUE_STATUS,
  RECORD_TYPE.LEAD,
  RECORD_TYPE.LOT,
  RECORD_TYPE.OFFER,
  RECORD_TYPE.PARTNER,
  RECORD_TYPE.PARTNER_GROUP,
  RECORD_TYPE.PHASE,
  RECORD_TYPE.PROJECT,
  RECORD_TYPE.PROPOSAL,
  RECORD_TYPE.PROPOSAL_TEMPLATE,
  RECORD_TYPE.PROFESSIONAL_SERVICE,
  RECORD_TYPE.PUBLIC_TOKEN,
  RECORD_TYPE.QUESTION,
  RECORD_TYPE.QUESTION_LIST,
  RECORD_TYPE.REMINDER,
  RECORD_TYPE.SPACE,
  RECORD_TYPE.SUPERVISOR,
  RECORD_TYPE.SURVEY,
  RECORD_TYPE.SURVEY_QUESTION,
  RECORD_TYPE.SURVEY_TYPE,
  RECORD_TYPE.TENANT,
  RECORD_TYPE.THEME,
  RECORD_TYPE.TICKET,
  RECORD_TYPE.TODO,
  RECORD_TYPE.TODO_LIST,
  RECORD_TYPE.UNIT,
  RECORD_TYPE.USER,
];

const SPACE_CATEGORIES = Object.freeze({
  CATEGORY_BICYCLE_STORAGE: 'bicyclestorage',
  CATEGORY_CELLAR: 'cellar',
  CATEGORY_GARAGE_BOX: 'garagebox',
  CATEGORY_OUTDOOR_SPACE: 'outdoor_space',
  CATEGORY_PARKING_SPOT: 'parkinglot',
  CATEGORY_STORAGE: 'storage',
  CATEGORY_WINECELLAR: 'winecellar',
  CATEGORY_OTHER: 'other',
});

const SUPERVISOR_ROLES = Object.freeze({
  ROLE_ADMIN: 'admin',
  ROLE_SUPPORT: 'support',
});

const SURVEY_STATUSES = {
  STATUS_ANSWERED: 'answered',
  STATUS_ANSWER_LATER: 'answer_later',
  STATUS_NO_OPINION: 'no_opinion',
  STATUS_UNANSWERED: 'unanswered',
};

const QUESTION_TYPES = {
  NPS: 'nps',
  OPEN_ENDED: 'open_ended',
  YES_NO: 'yes_no',
  SATISFACTION: 'satisfaction',
};

export const TENANT_PICTURE_FAVICON_URL = `https://storage.googleapis.com/${import.meta.env.VITE_GCP_BUCKET_ASSETS_PUBLIC}/login/engage_favicon.png`;

const TENANT_PICTURE_LOGO_FORMATS = Object.freeze({
  LOGO_FORMAT_ROUND: 'round',
  LOGO_FORMAT_SQUARE: 'square',
});

const TICKET_DIRECTIONS = Object.freeze({
  DIRECTION_EXTERNAL: 'external',
  DIRECTION_INBOUND: 'inbound',
  DIRECTION_OUTBOUND: 'outbound',
});

const TICKET_STATUSES = Object.freeze({
  STATUS_ONGOING: 'ongoing',
  STATUS_OPEN: 'open',
  STATUS_FEEDBACK: 'feedback',
  STATUS_CLOSED: 'closed',
});

export const TICKET_STATUS_COLOR = Object.freeze({
  [TICKET_STATUSES.STATUS_OPEN]: 'has-background-grey-lighter',
  [TICKET_STATUSES.STATUS_ONGOING]: 'has-background-warning',
  [TICKET_STATUSES.STATUS_FEEDBACK]: 'has-background-info has-text-white',
  [TICKET_STATUSES.STATUS_CLOSED]: 'has-background-success has-text-white',
});

export const TIMEZONES = Object.freeze([
  'Europe/Brussels',
  'Europe/Lisbon',
  'Europe/London',
  'Europe/Madrid',
]);

const TIME_UNITS = Object.freeze({
  TIME_UNIT_DAYS: 'days',
  TIME_UNIT_MONTHS: 'months',
  TIME_UNIT_WEEKS: 'weeks',
});

const UNIT_CATEGORIES = Object.freeze({
  CATEGORY_APARTMENT: 'apartment',
  CATEGORY_ALF: 'alf',
  CATEGORY_COMMERCIAL: 'commercial',
  CATEGORY_DUPLEX: 'duplex',
  CATEGORY_HOUSE: 'house',
  CATEGORY_LOFT: 'loft',
  CATEGORY_OFFICE: 'office',
  CATEGORY_PENTHOUSE: 'penthouse',
  CATEGORY_SME: 'sme',
  CATEGORY_STUDIO: 'studio',
});

const SUBCATEGORY_HOUSES = Object.freeze({
  SUBCATEGORY_HOUSE_DETACHED: 'detached',
  SUBCATEGORY_HOUSE_ROWHOUSE: 'rowhouse',
  SUBCATEGORY_HOUSE_SEMIDETACHED: 'semidetached',
});

const UNIT_SCOPES = Object.freeze({
  SCOPE_INVISIBLE: 'invisible',
  SCOPE_VISIBLE: 'visible',
});

const GROUP_CUSTOMER_SCOPES = Object.freeze({
  SCOPE_INVISIBLE: 'invisible',
  SCOPE_VISIBLE: 'visible',
});

const ATTACHMENT_TYPES = Object.freeze({
  ATTACHMENT_TYPE_BASE: 'base',
  ATTACHMENT_TYPE_SIGN: 'sign',
});

// Model Objects storing enums

export const Assignment = {
  ...ASSIGNMENT_TYPES,
  TYPES: Object.values(ASSIGNMENT_TYPES),
};

export const Attachment = {
  ...ATTACHMENT_TYPES,
  TYPES: Object.values(ATTACHMENT_TYPES),
};

export const Customer = {
  ...CUSTOMER_CATEGORIES,
  CATEGORIES: Object.values(CUSTOMER_CATEGORIES),
};

export const Document = {
  ...ATTACHMENT_CATEGORIES,
  CATEGORIES: Object.values(ATTACHMENT_CATEGORIES),
};

export const Employee = {
  ...EMPLOYEE_ROLES,
  ROLES: Object.values(EMPLOYEE_ROLES),
};

export const Export = {
  ...EXPORT_STATES,
  STATES: Object.values(EXPORT_STATES),
  ...EXPORT_TYPES,
  TYPES: Object.values(EXPORT_TYPES),
};

export const GroupCustomer = {
  ...GROUP_CUSTOMER_SCOPES,
  SCOPES: Object.values(GROUP_CUSTOMER_SCOPES),
};

export const Fraction = {
  ...INSTALLMENT_PRICE_FRACTION_TYPES,
  FRACTION_TYPES: Object.values(INSTALLMENT_PRICE_FRACTION_TYPES),
  ...INSTALLMENT_PRICE_TYPES,
  TYPES: Object.values(INSTALLMENT_PRICE_TYPES),
};

export const Issue = {
  ...ISSUE_STATUSES,
  ...ISSUE_APROVAL_STATUSES,
  STATUSES: Object.values(ISSUE_STATUSES),
};

export const Lead = {
  CATEGORIES: Object.values(CUSTOMER_CATEGORIES),
};

export const ProfileRecord = {
  ...EMAIL_NOTIFICATIONS_INTERVALS,
  EMAIL_NOTIFICATIONS_INTERVALS: Object.values(EMAIL_NOTIFICATIONS_INTERVALS),
  ...PROFILE_STATUSES,
  STATUSES: Object.values(PROFILE_STATUSES),
  STATUS_STYLES: PROFILE_STATUSES_STYLES,
  STATUS_STYLES_TEXT: PROFILE_STATUSES_STYLES_TEXT,
  ...PROFILE_TITLES,
  TITLES: Object.values(PROFILE_TITLES),
};

export const Project = {
  ...PROJECT_STATUSES,
  STATUSES: Object.values(PROFILE_STATUSES),
  ...MEASUREMENT_SYSTEMS,
  MEASUREMENT_SYSTEMS: Object.values(MEASUREMENT_SYSTEMS),
};

export const Reminder = {
  ...TIME_UNITS,
  TIME_UNITS: Object.values(TIME_UNITS),
};

export const Space = {
  ...SPACE_CATEGORIES,
  CATEGORIES: Object.values(SPACE_CATEGORIES),
};

export const Supervisor = {
  ...SUPERVISOR_ROLES,
  ROLES: Object.values(SUPERVISOR_ROLES),
};

export const SurveyQuestion = {
  ...QUESTION_TYPES,
  QUESTION_TYPES: Object.values(QUESTION_TYPES),
  ...SURVEY_STATUSES,
  STATUSES: Object.values(SURVEY_STATUSES),
};

export const Tenant = {
  ...CLIENT_STATUSES,
  STATUSES: Object.values(CLIENT_STATUSES),
  ...TENANT_PICTURE_LOGO_FORMATS,
  PICTURE_LOGO_FORMATS: Object.values(TENANT_PICTURE_LOGO_FORMATS),
  ...CLIENT_PACKAGE_TYPES,
  PACKAGE_TYPES: Object.values(CLIENT_PACKAGE_TYPES),
  PRICING_TYPES: Object.values(CLIENT_PRICING_TYPES),
  BUNDLE_TYPES: Object.values(CLIENT_BUNDLE_TYPES),
};

export const Ticket = {
  ...TICKET_DIRECTIONS,
  DIRECTIONS: Object.values(TICKET_DIRECTIONS),
  ...TICKET_STATUSES,
  STATUSES: Object.values(TICKET_STATUSES),
};

export const Unit = {
  ...CATEGORY_OUTDOORS,
  CATEGORY_OUTDOORS: Object.values(CATEGORY_OUTDOORS),
  ...ORIENTATIONS,
  ORIENTATIONS: Object.values(ORIENTATIONS),
  ...SUBCATEGORY_HOUSES,
  SUBCATEGORY_HOUSES: Object.values(SUBCATEGORY_HOUSES),
  ...UNIT_CATEGORIES,
  CATEGORIES: Object.values(UNIT_CATEGORIES),
  ...UNIT_SCOPES,
  SCOPES: Object.values(UNIT_SCOPES),
};
