import i18n from '@/i18n/index';

export default {
  methods: {
    toast_update_succeeded(itemName) {
      const message = itemName
        ? i18n.global.t('toast.update.succeeded', { itemName })
        : i18n.global.t('toast.update.successfully_saved');
      this.toast_open(message, 'is-success');
    },

    toast_create_succeeded(itemName) {
      const message = i18n.global.t('toast.create.succeeded', { itemName });
      this.toast_open(message, 'is-success');
    },

    toast_delete_succeeded(itemName) {
      const message = itemName
        ? i18n.global.t('toast.delete.succeeded', { itemName })
        : i18n.global.t('toast.delete.succeeded_default');
      this.toast_open(message, 'is-success');
    },

    toast_action_failed() {
      const message = i18n.global.t('toast.action.failed');
      this.toast_open(message, 'is-warning');
    },

    toast_upload_succeeded() {
      const message = i18n.global.t('toast.upload.succeeded');
      this.toast_open(message, 'is-success');
    },

    // custom success toast
    toast_success(message) {
      this.toast_open(message, 'is-success');
    },

    // custom fail toast
    toast_fail(message) {
      this.toast_open(message, 'is-warning');
    },

    toast_open(message, type) {
      this.$buefy.toast.open({ message, type, duration: type === 'is-success' ? 3000 : 6000 });
    },
  },
};
